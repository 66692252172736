<template>
  <div class="w-full min-h-screen flex h-full">
    <div class="bg-gray-800 w-[24vw] h-[100vh] max-lg:hidden fixed">
      <div class="flex justify-center items-center h-full">
        <img
          preload
          src="/long-hurst-logo.svg"
          alt="Logo da Hurst Capital"
          width="190"
          height="50"
          class="justify-center items-center w-32 xl:w-48"
        />
      </div>
    </div>
    <div class="w-[24vw] flex-shrink-0 max-lg:hidden"></div>
    <div class="w-full px-5 sm:px-10 md:px-20">
      <main>
        <div class="w-full h-full ml-auto mr-auto">
          <suspense>
            <slot></slot>
          </suspense>
        </div>
      </main>
    </div>
  </div>
</template>
